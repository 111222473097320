import { graphql, useStaticQuery } from "gatsby";

const useFooter = () => {
  const data = useStaticQuery(graphql`
    query UseFooter {
      allMarkdownRemark(filter: { collection: { eq: "footer" } }) {
        nodes {
          frontmatter {
            sideText
            poweredBy
            links {
              title
              svg
              url
            }
          }
        }
      }
    }
  `);

  return data.allMarkdownRemark.nodes.map((footer) => footer.frontmatter)[0];
};

export default useFooter;
