import React from "react";
import { css } from "@emotion/react";
import { COLOURS } from "./../../utilities/variables";
import ScrollAnimation from "react-animate-on-scroll";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

export default function QuestionFrame({ backgroundText, sizeDecrease }) {
  const breakpoints = useBreakpoint();
  const descFontSizes = [
    breakpoints.md ? "12rem" : "6.1rem",
    breakpoints.md ? "10rem" : "5.1rem",
    breakpoints.md ? "8rem" : "4.1rem",
    breakpoints.md ? "6.1rem" : "3.1rem",
  ];
  return (
    <div
      className="animated-fixed question container"
      css={css`
        padding: ${breakpoints.md ? "30vh" : "6rem"};
        ${sizeDecrease && "padding-bottom: 0;"}
        z-index: 100;
        position: relative;
      `}
    >
      {backgroundText.length &&
        backgroundText.split(" ").map((word, index) => (
          <ScrollAnimation
            key={`subtitle-animated-${index}`}
            animateIn={`animate__fadeInBottom${index % 2 ? `Left` : `Right`}`}
            animateOnce={true}
            offset={30}
            css={css`
              text-align: center;
              margin-bottom: ${breakpoints.md ? "30vh" : "8rem"};
            `}
          >
            <span
              css={css`
                font-weight: 900;
                text-transform: uppercase;
                color: ${COLOURS.white};
                opacity: 0.5;
                line-height: ${breakpoints.md ? "14.4rem" : "7.4rem"};
                font-size: ${sizeDecrease === true
                  ? descFontSizes[index % 4]
                  : descFontSizes[0]};
              `}
            >
              {word}
            </span>
          </ScrollAnimation>
        ))}
    </div>
  );
}
