import React from "react";
import { Helmet } from "react-helmet";

export default function SEO({ title, description, canonical }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description}></meta>
      <link
        rel="icon"
        type="image/png"
        href="/public/favicons/favicon-16x16.png"
        sizes="16x16"
      />

      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="application-name" content="Endre" />
      <meta name="apple-mobile-web-app-title" content="Endre" />
      <meta name="theme-color" content="#202124" />
      <meta name="msapplication-navbutton-color" content="#202124" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta name="msapplication-starturl" content="/" />
      <link rel="canonical" href={canonical}></link>
    </Helmet>
  );
}
