export const FONTS = {
  sanSerif: `"Roboto", "Helvetica", "Arial", sans-serif`,
  sanSerifText: `"Montserrat", sans-serif`,
  monoSpace: `"Major Mono Display", monospace`,
};

export const BREAK = {
  mobile: `@include breakpoint(maxWidth: 576px)`,
  tablet: `@include breakpoint(maxWidth: 768px)`,
  smallScreen: `@include breakpoint(maxWidth: 992px)`,
  largeScreen: `@include breakpoint(maxWidth: 1200px)`,
};

export const COLOURS = {
  black: `#202124`,
  white: `#ffffff`,
  red: `#da8f89`,
  pink: `#cea0ae`,
  green: `#b0d0d3`,
  yellow: `#f7e9b5`,
  workBackground: `#010101`,
};
