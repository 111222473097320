import React from "react";
import { css } from "@emotion/react";
import { COLOURS } from "./../../utilities/variables";
import { fullWidth } from "./../../utilities/mixins";
import ScrollAnimation from "react-animate-on-scroll";
import QuestionFrame from "./QuestionFrame";

export default function About({ title, content, backgroundText }) {
  return (
    <section
      className="about-wrapper"
      css={css`
        z-index: 100;
      `}
    >
      <QuestionFrame backgroundText={backgroundText} />
      <div
        className="content-wrapper"
        css={css`
          background: ${COLOURS.red};
          ${fullWidth}
          padding: 6rem 5rem;
        `}
      >
        <ScrollAnimation
          animateIn="animate__fadeInTopLeft"
          animateOnce={true}
          offset={0}
        >
          <h3>{title}</h3>
        </ScrollAnimation>

        <ScrollAnimation
          animateIn="animate__fadeInBottomRight"
          animateOnce={true}
          offset={0}
        >
          <p
            css={css`
              margin-bottom: 4rem;
              font-weight: 300;
              font-size: 2.4rem;
              line-height: 4.6rem;
              margin: 0;
            `}
          >
            {content}
          </p>
        </ScrollAnimation>
      </div>
    </section>
  );
}
