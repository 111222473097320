import React from "react";
import { css } from "@emotion/react";
import { COLOURS } from "./../../utilities/variables";
import ScrollAnimation from "react-animate-on-scroll";
import { fullWidth } from "./../../utilities/mixins";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

export default function Subtitle({ heading }) {
  const breakpoints = useBreakpoint();

  return (
    <div
      className="subtitle-wrapper"
      css={css`
        min-height: 100vh;
        background: ${COLOURS.black};
        padding: ${breakpoints.md ? "30vh" : "8rem"} 0;
        justify-content: center;
        ${fullWidth}
      `}
    >
      <h2
        css={css`
          margin: 0;
        `}
      >
        {heading.length &&
          heading.split(" ").map((word, index) => (
            <ScrollAnimation
              key={`subtitle-animated-${index}`}
              animateIn={`animate__fadeInBottom${index % 2 ? `Left` : `Right`}`}
              animateOnce={true}
              offset={36}
              css={css`
                text-align: center;
                margin-bottom: ${breakpoints.md ? "30vh" : "3rem"};
                color: ${COLOURS.red};
                font-size: 6.1rem;
              `}
            >
              <span
                // data-sal={`slide-${index % 2 ? `left` : `right`}`}
                css={css`
                  color: ${COLOURS.red};
                  font-size: ${breakpoints.md ? "12rem" : "6.1rem"};
                  text-transform: uppercase;
                  letter-spacing: 0.4rem;
                  font-weight: 200;
                  font-family: "Montserrat";
                `}
              >
                {word}
              </span>
            </ScrollAnimation>
          ))}
      </h2>
    </div>
  );
}
