import { css } from "@emotion/react";
import { COLOURS } from "./../../utilities/variables";
import React from "react";
import QuestionFrame from "./QuestionFrame";
import { fullWidth } from "./../../utilities/mixins";
import Img from "gatsby-image";
import Technology from "./Technology";

export default function Work({ image, backgroundText, technologies }) {
  return (
    <section className="work">
      <div
        className="question-wrapper"
        css={css`
          background: ${COLOURS.workBackground};
          z-index: 100;
          ${fullWidth}
        `}
      >
        <QuestionFrame backgroundText={backgroundText} sizeDecrease={true} />

        <Img
          css={css`
            margin-top: -50%;
          `}
          fluid={image.childImageSharp.fluid}
        />
      </div>
      <div
        css={css`
          background: ${COLOURS.workBackground};
          z-index: 100;
          ${fullWidth}
          padding: 0 2rem;
          overflow: auto;
        `}
      >
        {technologies.map((technology, index) => (
          <Technology
            key={`technology-element${index}`}
            even={(index + 1) % 2}
            {...technology}
          />
        ))}
      </div>
    </section>
  );
}
