import React, { useState } from "react";
import useFooter from "../../hooks/use-footer";
import { css } from "@emotion/react";
import { flexDiv } from "../../utilities/mixins";
import { COLOURS } from "../../utilities/variables";
import Mail from "../../../assets/svgs/mail.svg";
import Twitter from "../../../assets/svgs/twitter.svg";
import LinkedIn from "../../../assets/svgs/linkedin.svg";
import Git from "../../../assets/svgs/git.svg";
import CodePen from "../../../assets/svgs/codepen.svg";
import BackgroundCircle from "./BackgroundCircle";
import ScrollAnimation from "react-animate-on-scroll";

export default function Footer() {
  const { links, poweredBy, sideText } = useFooter();
  const [animated, setAnimated] = useState(false);

  const svgComponents = {
    mail: <Mail />,
    twitter: <Twitter />,
    linkedin: <LinkedIn />,
    gitlab: <Git />,
    codepen: <CodePen />,
  };

  return (
    <footer
      css={css`
        background: ${COLOURS.black};
        padding: 6rem 2rem;
      `}
    >
      <ScrollAnimation
        animateIn="animate__fadeInUp"
        animateOnce={true}
        offset={0}
        afterAnimatedIn={function afterAnimatedIn() {
          setAnimated(true);
        }}
        css={css`
          ${flexDiv()}
          justify-content: space-evenly;
        `}
      >
        {links.map((link, index) => (
          <a
            key={`contact_key_${index}`}
            href={link.url}
            target="_blank"
            rel="noreferrer"
            aria-label={`View my ${link.title} page.`}
            css={css`
              display: block;
              position: relative;
              overflow: hidden;
              width: 6rem;
              height: 6rem;
              border-radius: 50%;
              margin: 0 4rem 4rem;
              align-self: center;
              transition: transform 0.2s ease;

              &:hover {
                transform: scale(1.1);
              }
            `}
          >
            <span
              css={css`
                display: none;
              `}
            >
              {link.title}
            </span>
            <span
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                svg circle {
                  stroke: ${animated ? COLOURS.white : "transparent"};
                }
              `}
            >
              {animated && <BackgroundCircle delay={index * 300} />}
            </span>
            <span
              css={css`
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 3rem;
                height: 3rem;
                color: ${COLOURS.white};
                svg {
                  width: 3rem;
                  height: 3rem;
                  position: relative;
                  z-index: 100;
                  path {
                    fill: ${COLOURS.white};
                  }
                }
              `}
            >
              {svgComponents[link.svg]}
            </span>
          </a>
        ))}
      </ScrollAnimation>
      <ScrollAnimation
        animateIn="animate__fadeInUp"
        animateOnce={true}
        offset={0}
        css={css`
          font-family: "Montserrat";
          font-weight: bold;
          font-size: 1.8rem;
          color: ${COLOURS.white};
          text-align: center;
          margin-bottom: 4rem;
          position: relative;
          z-index: 100;
        `}
      >
        {sideText}
      </ScrollAnimation>
      <ScrollAnimation
        animateIn="animate__fadeInUp"
        animateOnce={true}
        offset={0}
        css={css`
          font-weight: 300;
          font-size: 1.4rem;
          color: ${COLOURS.white};
          text-align: center;
          line-height: 191%;
          position: relative;
          z-index: 100;
        `}
      >
        {poweredBy}
      </ScrollAnimation>
    </footer>
  );
}
