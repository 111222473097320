import React from "react";
import About from "./About";
import Footer from "../general/Footer";
import Hero from "./Hero";
import Intro from "./Intro";
import Work from "./Work";
import SEO from "../general/SEO";
import useSeo from "../../hooks/use-seo";
import Contact from "./Contact";

export default function HomePage(data) {
  const seo = useSeo();
  return (
    <React.Fragment>
      <SEO {...seo} />
      <main className="content home-page">
        <Hero {...data.hero} />
        <Intro {...data.intro} />
        <About {...data.about} />
        <Work {...data.work} />
        <Contact />
      </main>
      <Footer />
    </React.Fragment>
  );
}
