import React from "react";
import NetifyContactForm from "../forms/NetifyContactForm";
import { css } from "@emotion/react";
import { COLOURS } from "./../../utilities/variables";
// import ScrollAnimation from "react-animate-on-scroll";

export default function Contact() {
  return (
    <section
      css={css`
        padding: 4rem 2rem;
        background: ${COLOURS.black};
        color: ${COLOURS.white};
        text-align: center;
      `}
    >
      <div
        css={css`
          background: linear-gradient(
            206.54deg,
            rgba(218, 143, 137, 0.6) -23.33%,
            rgba(247, 233, 181, 0.6) 119.5%
          );
          backdrop-filter: blur(20px);
          border-radius: 1rem;
          padding: 4rem 2rem;
          max-width: 38rem;
          margin: 10rem auto;
        `}
      >
        <h2
          css={css`
            font-size: 4rem;
            margin-bottom: 2rem;
          `}
        >
          Get in touch
        </h2>
        <p
          css={css`
            opacity: 0.8;
            font-size: 1.6rem;
            line-height: 200%;
            font-weight: 300;
            margin-top: 0;
            margin-bottom: 2rem;
          `}
        >
          Send a message or details of the project you'd like me to be a part of
          and I'll get back to you ASAP.
        </p>
        <NetifyContactForm />
      </div>
    </section>
  );
}
