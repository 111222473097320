import React from "react";
import { fullWidth } from "./../../utilities/mixins";
import { css } from "@emotion/react";

export default function BackgroundVideo({ video, videoImage }) {
  return (
    <div
      className="video-wrapper"
      css={css`
        ${fullWidth}

        padding-top: 56.25%;
        z-index: -1;
      `}
    >
      <div
        css={css`
          width: 100%;
          height: auto;
          position: fixed;
          top: 0;
        `}
      >
        <video
          autoPlay
          loop
          muted
          css={css`
            width: calc(100vh * 16 / 9);
            height: 100vh;
            margin-left: 50%;
            transform: translateX(-50%);
          `}
        >
          <source src={video.mp4} type="video/mp4" />
          <source src={video.webm} type="video/webm" />

          <img src={videoImage.childImageSharp.src} alt="Video background" />
          {/* <track kind="captions" srcLang="en" src="" /> */}
        </video>
      </div>
    </div>
  );
}
