import React, { useState } from "react";
import StatusMessage from "./StatusMessage";

export default function NetifyContactForm() {
  const formName = "endre-soo-contact";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [forJob, setForJob] = useState(false);
  const [message, setMessage] = useState("");
  const [formSuccess, setFormSuccess] = useState(-1);

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const submitContactForm = () => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": formName,
        name: name,
        email: email,
        for_job: forJob,
        message: message,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          setFormSuccess(1);
          setEmail("");
          setForJob(false);
          setName("");
          setMessage("");
          setFormSuccess(1);
        } else setFormSuccess(0);
      })
      .catch((error) => {
        // TODO: sentry log error
        setFormSuccess(0);
      });
  };

  return (
    <form
      name={formName}
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={(e) => {
        e.preventDefault();
        submitContactForm();
      }}
    >
      <input
        placeholder="name"
        className="person"
        type="text"
        name="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <input
        placeholder="email"
        className="envelope"
        type="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <label htmlFor="contactForJob">
        <input
          type="checkbox"
          id="contactForJob"
          name="for_job"
          value={forJob}
          checked={forJob}
          onChange={(e) => setForJob(e.target.checked)}
        />
        I’m thinking about hiring Endre
      </label>
      <textarea
        placeholder="message"
        className="chat"
        name="message"
        rows={8}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required
      />
      <input type="hidden" name="form-name" value="endre-soo-contact" />
      <button type="submit">Send</button>

      {formSuccess >= 0 && (
        <StatusMessage
          status={formSuccess}
          message={
            formSuccess === 1
              ? `I'll get back to you as soon as possible!`
              : `Something went wrong. I'm most certainly working on a fix now. `
          }
          onClick={() => setFormSuccess(-1)}
        />
      )}
    </form>
  );
}
