import React, { useState } from "react";
import Img from "gatsby-image";
import { COLOURS } from "../../utilities/variables";
import { transitionCB } from "../../utilities/mixins";
import { css } from "@emotion/react";
import Backend from "../../../assets/svgs/backend.svg";
import Frontend from "../../../assets/svgs/frontend.svg";
import Server from "../../../assets/svgs/server.svg";
import Design from "../../../assets/svgs/design.svg";
import ScrollAnimation from "react-animate-on-scroll";

export default function Technology({ title, content, images, graphic, even }) {
  const hoverEffects = {
    even: [
      `transform: translate(0, 0);`,
      `transform: translate(1rem, -2rem);`,
      `transform: translate(2rem, 2rem);`,
    ],
    odd: [
      `transform: translate(-2rem, 2rem);`,
      `transform: translate(-1rem, -2rem);`,
      `transform: translate(0, 0);`,
    ],
  };
  const graphicColours = {
    backend: {
      start: "#000000",
      end: COLOURS.red,
    },
    frontend: {
      start: COLOURS.red,
      end: COLOURS.pink,
    },
    server: {
      start: COLOURS.pink,
      end: COLOURS.yellow,
    },
    design: {
      start: COLOURS.yellow,
      end: COLOURS.green,
    },
  };

  const [hovered, setHovered] = useState(false);
  const [animated, setAnimated] = useState(false);

  const graphicMapping = {
    frontend: <Frontend />,
    backend: <Backend />,
    server: <Server />,
    design: <Design />,
  };

  return (
    <ScrollAnimation
      animateIn={`animate__fadeInBottom${even ? "Right" : "Left"}`}
      animateOnce={true}
      offset={0}
      // duration is 1s by default
      afterAnimatedIn={function afterAnimatedIn() {
        setAnimated(true);
      }}
    >
      <section
        className="technology"
        css={css`
          margin-bottom: 9rem;
        `}
      >
        <div
          className="content-wrapper"
          css={css`
            position: relative;
            ${even
              ? `margin-right: 6rem;`
              : `margin-left: 6rem; text-align: right;`}
            &::before {
              /* dot */
              content: "";
              width: 1rem;
              height: 1rem;
              border-radius: 100%;
              background: ${graphicColours[graphic].end};
              position: absolute;
              top: 50%;
              ${even ? `right: 0` : `left: 0`};
              transform: translate(${even ? "3.5rem" : "-3.5rem"}, -50%);
              z-index: 100;
              transition: visibility 1s ease-in;
              visibility: ${animated ? "visible" : "hidden"};
              ${animated && "animation: blink 1s 1 alternate 1.8s;"}
            }
            &::after {
              /* line */
              content: "";
              position: absolute;
              top: 0;
              ${even ? `right: 0` : `left: 0`};
              transition: height 1.2s ease-in;
              height: ${animated ? "calc(100% + 26.4rem)" : "0"};
              width: 2px;
              background: linear-gradient(
                180deg,
                ${graphicColours[graphic].start} 0%,
                ${graphicColours[graphic].end} 100%
              );
              transform: translate(${even ? "3.1rem" : "-3.1rem"}, -14.4rem);
            }
          `}
        >
          <h4
            css={css`
              font-size: 3.2rem;
              letter-spacing: 0.24em;
              color: ${COLOURS.white};
              margin-bottom: 5rem;
              text-transform: uppercase;
              font-weight: 500;
            `}
          >
            {title}
          </h4>
          <p
            css={css`
              font-size: 2.4rem;
              line-height: 4.6rem;
              font-weight: 300;
              color: ${COLOURS.white};
            `}
          >
            {content}
          </p>
          <div
            className="images"
            css={css`
              display: flex;
              flex-flow: row nowrap;
              outline: transparent;
            `}
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
            onFocus={() => setHovered(true)}
            onBlur={() => setHovered(false)}
            role="link"
            tabIndex="0"
            aria-label={`Interactive ${title} images`}
          >
            {images.map((image, index) => {
              return (
                <div
                  key={`image-${title}-${index}`}
                  css={css`
                    background-color: ${COLOURS.white};
                    position: relative;
                    border-radius: 1rem;
                    padding: 1rem;
                    overflow: hidden;
                    filter: drop-shadow(1.5rem 1.5rem 2rem rgba(0, 0, 0, 0.2));
                    width: 50%;
                    max-width: 14rem;
                    margin: auto 0;
                    z-index: ${index + 1};
                    transform: translate(
                      ${(even ? -50 * index : (2 - index) * 50) + "%"},
                      0
                    );
                    transition: all 0.6s ${transitionCB};
                    ${images.length > 0 &&
                    hovered &&
                    (even ? hoverEffects.even[index] : hoverEffects.odd[index])}

                    &::after {
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      background: rgba(0, 0, 0, 0.6);
                    }
                  `}
                >
                  <Img fluid={image.image.childImageSharp.fluid} />
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="graphic"
          css={css`
            margin: 7rem auto;
            text-align: center;
            position: relative;

            &::after {
              /* line */
              content: "";
              position: absolute;
              height: 2px;
              background: ${graphicColours[graphic].end};
              left: 50%;
              top: 50%;
              transform: translate(-50%, -3px);
              z-index: -1;
              width: ${animated ? `calc(100% - 5.8rem)` : `0`};
              transition: width 1.2s ease-in;
            }

            svg {
              width: 6rem;
              height: 6rem;
              padding: 2rem;
              box-sizing: content-box;
              background: ${COLOURS.workBackground};
              visibility: ${animated ? `visible` : `hidden`};
              transition: visibility 0.3s ease-in;
              & > path {
                fill: ${graphicColours[graphic].end};
              }
            }
          `}
        >
          {graphicMapping[graphic]}
        </div>
      </section>
    </ScrollAnimation>
  );
}
