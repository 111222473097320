import React from "react";
import { css } from "@emotion/react";
import { flexDiv } from "./../../utilities/mixins";
import { COLOURS } from "./../../utilities/variables";

import Success from "./../../../assets/svgs/cloud-check.svg";
import Alert from "../../../assets/svgs/exclamation-triangle.svg";

export default function StatusMessage({ status, message }) {
  return (
    <div
      tab-index={-1}
      css={css`
        margin-top: 4rem;
        border-radius: 1rem;
        text-align: left;
        ${flexDiv("row", "nowrap")}
        padding: 2rem;
        background: ${status ? COLOURS.green : COLOURS.red};
        color: ${status ? COLOURS.black : COLOURS.white};
        > svg {
          width: 3rem;
          height: auto;
          margin: 0 1rem auto 0;
        }
      `}
    >
      {status ? <Success /> : <Alert />}
      <div>
        <b>{status ? "Success" : "Error"}</b> {message}
      </div>
    </div>
  );
}
