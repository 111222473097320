import { graphql, useStaticQuery } from "gatsby";

const useSeo = () => {
  const data = useStaticQuery(graphql`
    query UseSeo {
      allMarkdownRemark(filter: { collection: { eq: "seo" } }) {
        nodes {
          frontmatter {
            title
            description
            canonical
          }
        }
      }
    }
  `);

  return data.allMarkdownRemark.nodes.map((footer) => footer.frontmatter)[0];
};

export default useSeo;
