import React from "react";
import Subtitle from "./Subtitle";
import BackgroundVideo from "./BackgroundVideo";
import backgroundVideoMP4 from "./../../../assets/videos/pexels-pavel-danilyuk-5495845.min.mp4";
import backgroundVideoWEBM from "./../../../assets/videos/pexels-pavel-danilyuk-5495845.min.webm";
import QuestionFrame from "./QuestionFrame";
import { css } from "@emotion/react";
import { COLOURS } from "./../../utilities/variables";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import ScrollAnimation from "react-animate-on-scroll";

export default function Hero({
  heading,
  title,
  content,
  videoImage,
  backgroundText,
}) {
  const breakpoints = useBreakpoint();

  return (
    <section className="intro">
      <Subtitle heading={heading} />
      <BackgroundVideo
        video={{ mp4: backgroundVideoMP4, webm: backgroundVideoWEBM }}
        videoImage={videoImage}
      />
      <QuestionFrame backgroundText={backgroundText} />

      <ScrollAnimation
        animateIn="animate__fadeInUp"
        animateOnce={true}
        offset={0}
      >
        <section
          className="intro-content"
          css={css`
            background: ${COLOURS.white};
            box-shadow: 1.5rem 1.5rem 2rem rgba(0, 0, 0, 0.2);
            border-radius: 1rem;
            ${breakpoints.xs
              ? `margin: 3rem auto; max-width: 34rem;`
              : `margin: 3rem;`}
            padding: 4rem 2rem;
          `}
        >
          <h3>{title}</h3>
          <p
            css={css`
              margin-bottom: 4rem;
              font-weight: 300;
              font-size: 2.4rem;
              line-height: 4.6rem;
              margin: 0;
            `}
          >
            {content}
          </p>
        </section>
      </ScrollAnimation>
      {/* Content */}
      {/* What do I do */}
      {/* Content */}
    </section>
  );
}
