import React, { useEffect, useState } from "react";

export default function BackgroundCircle({ delay }) {
  const [render, setRender] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRender(true);
    }, delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return render ? (
    <svg
      version="1.1"
      id="circle"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <circle
        fill="none"
        stroke="#ffffff"
        strokeWidth="4"
        strokemitterlimit="0"
        cx="50"
        cy="50"
        r="48"
        strokeDasharray="360"
        strokeLinecap="round"
        transform="rotate(-90 ) translate(-100 0)"
      >
        <animate
          attributeName="stroke-dashoffset"
          values="360;0"
          dur="1200ms"
          repeatCount="1"
        ></animate>
      </circle>
    </svg>
  ) : (
    <div></div>
  );
}
