import React from "react";
import BackgroundImage from "gatsby-background-image";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

// style
import { css } from "@emotion/react";
import { fullWidth, flexDiv } from "./../../utilities/mixins";
import { COLOURS } from "./../../utilities/variables";
import ScrollAnimation from "react-animate-on-scroll";

export default function Hero({ title, image }) {
  const breakpoints = useBreakpoint();
  return (
    <section className="hero">
      <BackgroundImage
        fluid={image.childImageSharp.fluid}
        backgroundColor={`#202124`}
        className={`hero-background`}
        css={css`
          ${fullWidth}
          min-height: 100vh;
          ${flexDiv()}
          padding: 4rem 2rem;
        `}
      >
        <ScrollAnimation
          animateIn="animate__fadeIn"
          animateOnce={true}
          css={css`
            margin: auto;
          `}
        >
          <h1
            css={css`
              font-style: normal;
              font-weight: 900;
              font-size: ${breakpoints.l
                ? "12rem"
                : breakpoints.sm
                ? "10rem"
                : "8rem"};
              color: ${COLOURS.white};
              text-align: center;
            `}
          >
            {title.split("").map((char, index) => {
              if (char === "." || char === `'`)
                return (
                  <span
                    key={`char-${index}-${char}`}
                    css={css`
                      color: ${COLOURS.red};
                    `}
                  >
                    {char}
                  </span>
                );
              else if (char === " ") return <br key={`char-${index}-br`} />;
              else return char;
            })}
          </h1>
        </ScrollAnimation>
      </BackgroundImage>
    </section>
  );
}
