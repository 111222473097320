import React from "react";
import useHomePage from "../hooks/use-home-page";
import HomePage from "./../components/home-page/HomePage";
import "./../../assets/styling/global.scss";
import "animate.css/animate.min.css";

const Home = () => {
  const homePage = useHomePage();
  return <HomePage {...homePage} />;
};

export default Home;
