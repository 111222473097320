export const fullWidth = `
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
`;

export const backgroundImageCenter = `
	backgroundPosition: center,
	backgroundSize: cover,
	backgroundRepeat: no-repeat,
`;

export const flexDiv = (direction = "row", wrap = "wrap") => `
	display: flex;
	flex-flow: ${direction} ${wrap};
`;

export const transitionCB = `
	cubic-bezier(0.175, 0.885, 0.32, 1.275)
`;
