import { graphql, useStaticQuery } from "gatsby";

const useHomePage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { collection: { eq: "home" } }) {
        nodes {
          frontmatter {
            hero {
              title
              image {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            intro {
              heading
              title
              content
              videoImage {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              backgroundText
            }
            about {
              title
              content
              backgroundText
            }
            work {
              image {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              backgroundText
              technologies {
                title
                content
                images {
                  image {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                graphic
              }
            }
          }
        }
      }
    }
  `);

  return data.allMarkdownRemark.nodes.map((homePage) => ({
    hero: homePage.frontmatter.hero,
    intro: homePage.frontmatter.intro,
    about: homePage.frontmatter.about,
    work: homePage.frontmatter.work,
  }))[0];
};

export default useHomePage;
